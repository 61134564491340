import React from 'react';
import "./index.css";

// Pages
import Home from "./pages/Home/Home";

function App() {
  return (
    <Home /> 
  );
}

export default App;
